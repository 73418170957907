// Import dependencies
import React from "react"

// Import components
import Layout from "../components/layouts/layout"
import SEO from "../components/seo/seo"
import Header from "../components/header/header"
import RecycledHeader from "../components/recycled/RecycledHeader"
import TextSection from "../components/general/textSection"
import TitleSection from "../components/general/titleSection"
import TextBox from "../components/general/textBox"
import {
  TitleText,
  SubTitleText,
  PageContainer,
  Margins,
  ParagraphText,
  Box,
  Gap150,
} from "../components/styled/UILibrary"

// Bootstrap components
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

// Import styles
import "./styles/recycled.css"
import "./styles/history.css"
import "./styles/home.css"
import "./styles/scrollbar.css"
import "../components/header/header.css"

// Import images
import ShreddedPlexiglass from "../components/images/shredded"
import ShreddedPlexiglassSm from "../components/images/shreddedSm"
import Aquarium from "../components/images/auquarium"
import Forrest from "../components/images/forrest"
import FlameIcon from "../assets/icons/flame.svg"
import BGButton from "../components/general/bgButton"
import OutlineButton from "../components/general/outlineButton"

// Load lodash
var _ = require("lodash")

export default class Recycled extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showHeader: false,
      width: 0,
    }
    this.handleWaypointEnter = this.handleWaypointEnter.bind(this)
    this.handleWaypointLeave = this.handleWaypointLeave.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }

  handleWaypointEnter() {
    this.setState({ showHeader: false })
  }
  handleWaypointLeave() {
    this.setState({ showHeader: true })
  }

  handleScroll = () => {
    let scrollTop = window.scrollY
    if (scrollTop > window.innerHeight) {
      if (!this.state.showHeader) {
        this.setState({ showHeader: true })
      }
    } else {
      if (this.state.showHeader) {
        this.setState({ showHeader: false })
      }
    }
  }

  componentDidMount() {
    this.setState({ width: window.innerWidth })
    window.addEventListener("scroll", _.throttle(this.handleScroll, 100))

    // Hello for developers
    console.clear()
    console.log(
      "%cDesigned & developed by Dmitry Poliyivets – https://www.linkedin.com/in/dpoliyivets/",
      "background: #0000FF; color: #ffffff; font-size: 12px; padding: 25px; font-family: 'Montserrat', sans-serif; line-height: 22px"
    )
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", _.throttle(this.handleScroll, 100))
  }

  render() {
    return (
      <Layout>
        <SEO title="Recycled" />

        <div className="history__container">
          {/* Beautiful header component */}
          <RecycledHeader />

          <Header toggle toggleValue={this.state.showHeader} recycled />

          {/* Spiroplastic’s exceptional recycling process */}
          <PageContainer margin={"0"}>
            <Margins>
              <Gap150 name="green" />

              <TextSection
                smallTitle="ENVIRONMENTALY FRIENDLY"
                title="Our commitment"
                paragraph={
                  "Acrylic lasts for years. Strength and durability, as well as transparency, are the material's main assets. Since it is not biodegradable and lasts for decades by design, acrylic needs to stay out of landfills as a first priority. At Spiroplastic, we have made a commitment to a circular economy for acrylic, where our cast sheets are made from our own recycled materials. We are a major recycling center for acrylic waste as well as a manufacturer of the highest quality sheets."
                }
                green
              />

              <Gap150 name="process" />

              <TextSection
                smallTitle="OUR PROCESS"
                title="Spiroplastic’s exceptional recycling process."
                paragraph={[
                  "The majority of recycling centers will not take acrylic sheets. They are difficult to break down for recovery of usable materials. We do recycle acrylic sheets. Acrylic is what is called a Group 7 plastic which along with acrylic consists of nylon and styrene. These plastics are considered non-recyclable. However, we specialize in acrylic manufacturing from recycled materials. We are able to recover usable material with a purity of at least 99%.",
                  <br />,
                  <br />,
                  "Our commitment to reducing waste from landfills can’t be done without a great product. We produce standardized high-quality cast acrylic sheets from PMMA scrap that can replace the ones made from raw materials. Don’t take our word for it, order a free sample today ",
                ]}
                green
              />
              <div style={{ marginTop: 30, width: 220 }}>
                <OutlineButton title="CONTACT NOW" destination="/contact" />
              </div>
            </Margins>
          </PageContainer>
        </div>

        <Gap150 name="step-1" />

        {/* Step 1 */}
        <div className="recycled__step-1-container">
          {/* Shredded plexisglass image */}
          <div className="recycled__shredded-image-lg">
            <ShreddedPlexiglass />
          </div>
          <div className="recycled__shredded-image-sm">
            <ShreddedPlexiglassSm />
          </div>

          <div className="recycled__step-1-title">
            <PageContainer style={{ marginTop: 0 }}>
              <Margins>
                <TitleSection
                  smallTitle="STEP 1"
                  title="First, we shred the scrap sheets."
                  green
                  fullWidth
                />
              </Margins>
            </PageContainer>
          </div>
        </div>

        {/* Step 2 */}
        <div
          className="recycled__step-1-container recycled__step-2-bg"
          name="step-2"
        >
          <PageContainer style={{ marginTop: 0 }}>
            <Margins>
              <Gap150 />
              <Container fluid style={{ margin: 0, padding: 0 }}>
                <Row>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <TextSection
                      smallTitle="STEP 2"
                      title="Next, we heat things up"
                      paragraph={
                        "This is where we use the pyrolysis process to get the monomers out of the shredded acrylic sheets. Monomers are molecules that easily bond with other polymer forming molecules."
                      }
                      green
                    />
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className="recycled__pyrolysis">
                      <Box padding="40px" rounded>
                        <div>
                          <TitleText minSize={20} maxSize={30}>
                            Pyrolysis
                          </TitleText>
                          <div className="recycled__pyrolysis-container">
                            <ParagraphText
                              minSize={18}
                              maxSize={20}
                              style={{ marginTop: 30, display: "flex" }}
                            >
                              Pyrolysis is the thermal decomposition of
                              materials at elevated temperatures in an inert
                              atmosphere. It involves a change of chemical
                              composition and is irreversible.
                            </ParagraphText>
                            <div>
                              <FlameIcon className="recycled__flame-icon" />
                            </div>
                          </div>
                        </div>
                      </Box>
                    </div>
                  </Col>
                </Row>
              </Container>
              <Gap150 />
            </Margins>
          </PageContainer>
        </div>

        {/* Step 3 */}
        <div className="recycled__step-3-container" name="step-3">
          {/* Aquarium image */}
          <Aquarium />

          <div className="recycled__step-3-content-container">
            <PageContainer style={{ marginTop: 0 }}>
              <Margins>
                <Container fluid style={{ margin: 0, padding: 0 }}>
                  <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                      <SubTitleText minSize={14} maxSize={16} green shadow>
                        STEP 3
                      </SubTitleText>
                      <TitleText minSize={22} maxSize={40} white shadow>
                        New objects are casted from the melted waste
                      </TitleText>
                    </Col>
                  </Row>
                </Container>
              </Margins>
            </PageContainer>
          </div>
        </div>

        {/* Recycling is the way */}
        <Gap150 name="recycled-needs-title" />
        {/* <div className="history__container">
          <PageContainer margin={"0"}>
            <Margins>
              <Gap150 name="recycled-needs-title" />

              <TextSection
                smallTitle="DURABILITY"
                title="Excellent for indoor and outdoor use"
                paragraph={
                  "Intense UV rays, high temperatures, and an ultra dry climate have been no deterrent to Spiroplastic becoming Egypt’s leading manufacturer of acrylic sheets. Our customers know we produce the highest-quality acrylic because our products are a part of the backdrop of Egyptian society, standing up to one of the world’s harshest environments day after day."
                }
                green
              />

              <Gap150 name="vision" />

              <TitleSection
                smallTitle="VISION"
                title="Recycling is the way."
                green
                margin={10}
              />
              <Container
                fluid
                style={{ margin: 0, padding: 0 }}
                className="home__equal"
              >
                <Row>
                  <Col
                    xl={4}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    className="home__column-padding"
                  >
                    <TextBox
                      noHover
                      title="0 emissions"
                      paragraph="Manage not to polute more and lorem ipsum."
                      green
                    />
                  </Col>
                  <Col
                    xl={4}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    className="home__column-padding"
                  >
                    <TextBox
                      noHover
                      title="Only 10%"
                      paragraph="Of recycled products need any virgin material."
                      green
                    />
                  </Col>
                  <Col
                    xl={4}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    className="home__column-padding"
                  >
                    <TextBox
                      noHover
                      title="At least 99%"
                      paragraph="Purity lorem ipsum dolor sit amet, consectetur."
                      green
                    />
                  </Col>
                </Row>
              </Container>
            </Margins>
          </PageContainer>
        </div> */}

        <Gap150 />

        {/* Forrest & Call to action */}
        <div className="recycled__step-3-container">
          {/* Aquarium image */}
          <Forrest />

          <div className="recycled__call-to-action">
            <div className="recycled__call-to-action-inner">
              <TitleText minSize={30} maxSize={60} white shadow center>
                Order a free-sample now. UK and EU delivery.
              </TitleText>
              <BGButton
                destination="/contact"
                title="GO TO CONTACTS PAGE"
                margin={
                  this.state.width < 400
                    ? { marginTop: 40, width: "80vw" }
                    : { marginTop: 40 }
                }
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
