import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className }) => (
    <StaticQuery query={graphql`
      query {
        desktop: file(relativePath: { eq: "recycled-forrest.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
                ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
     render={data => {
       // Set ImageData.
       const imageData = data.desktop.childImageSharp.fluid
       return (
          <BackgroundImage 
            Tag="section"
            fluid={imageData}
            backgroundColor={`#ffffff`}
          >
            <div style={{ width: '100vw', height: '100vh' }} />
          </BackgroundImage>
       )
     }
     }
    />
)

const StyledBackgroundSection = styled(BackgroundSection)`
  background: no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`

export default StyledBackgroundSection
