// Import dependencies
import React from 'react'
import { Link } from 'gatsby'
import { SizeMe } from 'react-sizeme'

// Import components
import Loader from '../loader/Loader'
import ISOContainer from '../general/isoContainer'
import { TitleText } from '../styled/UILibrary'

// Import styles
import '../../pages/styles/recycled.css'

// Import images
import LogoWhite from '../../assets/icons/textual-logo-white.svg'
import headerLg from '../../assets/images/recycled-head-lg.jpg'
import headerMd from '../../assets/images/recycled-head-md.jpg'
import headerSm from '../../assets/images/recycled-head-sm.jpg'
import headerXs from '../../assets/images/recycled-head-xs.jpg'
import iso14001 from '../../assets/images/iso-14001.png'


export default class RecycledHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = { imageLoaded: false };
        this.hideLoader = this.hideLoader.bind(this);
    }

    hideLoader() {
        this.setState({ imageLoaded: true });
    }

    render() {
        return (
            <SizeMe>
                {({ size }) =>
                    <div className='recycled__header-container'>
                        {/* Loader for the large header images to load */}
                        {
                            !this.state.imageLoaded ?
                                <Loader />
                                :
                                null
                        }

                        {/* Header */}
                        <div className='header__container' style={{ backgroundColor: 'transparent', border: 0, position: "absolute" }}>
                            <div className='header__container-inner'>
                                <Link to='/' aria-label="Logo">
                                    <div className='header__logo-container' style={{ border: 0 }}>
                                        <LogoWhite alt='spiroplastic-logo' className='header__logo' />
                                    </div>
                                </Link>
                                <div className='header__links-container'>
                                    <Link to='/' className='header__link header__link-white'>HOME</Link>
                                    <Link to='/recycled' className='header__link header__link-white'>RECYCLED</Link>
                                    <Link to='/history' className='header__link header__link-white'>HISTORY</Link>
                                    <Link to='/about' className='header__link header__link-white'>ABOUT</Link>
                                    <Link to='/products' className='header__link header__link-white'>PRODUCTS</Link>
                                    <Link to='/contact' className='header__link header__link-white'>CONTACT</Link>
                                </div>
                            </div>
                        </div>

                        {/* Header images of all sizes */}
                        <img src={headerLg} className='recycled__header-image recycled__header-image-lg' alt='waste-problem' onLoad={this.hideLoader} />
                        <img src={headerMd} className='recycled__header-image recycled__header-image-md' alt='waste-problem' onLoad={this.hideLoader} />
                        <img src={headerSm} className='recycled__header-image recycled__header-image-sm' alt='waste-problem' onLoad={this.hideLoader} />
                        <img src={headerXs} className='recycled__header-image recycled__header-image-xs' alt='waste-problem' onLoad={this.hideLoader} />

                        {/* Top 100vh x 100vw */}
                        <div className='recycled__header-top'>
                            <div className='recycled__iso-container'>
                                <ISOContainer
                                    isoTitle='ISO 14001:2015'
                                    title1='Environmental'
                                    title2='Management'
                                    image={iso14001}
                                    link='https://www.iso.org/iso-14001-environmental-management.html'
                                />
                            </div>
                        </div>

                        {/* Top titles for different screens */}
                        <div className='recycled__header-title recycled__header-image-lg' style={{ top: size.width * 1.678 * .175 }}>
                            <TitleText minSize={40} maxSize={65} white shadow nolineheight>
                                Our planet is beautiful.
                            </TitleText>
                        </div>
                        <div className='recycled__header-title recycled__header-image-md' style={{ top: size.width * 2.119 * .178 }}>
                            <TitleText minSize={40} maxSize={65} white shadow nolineheight>
                                Our planet is beautiful.
                            </TitleText>
                        </div>
                        <div className='recycled__header-title recycled__header-image-sm' style={{ top: size.width * 3.355 * .17 }}>
                            <TitleText minSize={40} maxSize={65} white shadow nolineheight>
                                Our planet is beautiful.
                            </TitleText>
                        </div>
                        <div className='recycled__header-title recycled__header-image-xs' style={{ top: size.width * 5.752 * .17 }}>
                            <TitleText minSize={40} maxSize={65} white shadow nolineheight>
                                Our planet is beautiful.
                            </TitleText>
                        </div>

                        {/* Top titles for different screens */}
                        <div className='recycled__header-title recycled__header-image-lg' style={{ bottom: size.width * 1.678 * .20 }}>
                            <TitleText minSize={40} maxSize={65} white shadow nolineheight>
                                But we don't take care of it.
                            </TitleText>
                        </div>
                        <div className='recycled__header-title recycled__header-image-md' style={{ bottom: size.width * 2.119 * .20 }}>
                            <TitleText minSize={40} maxSize={65} white shadow nolineheight>
                                But we don't take care of it.
                            </TitleText>
                        </div>
                        <div className='recycled__header-title recycled__header-image-sm' style={{ bottom: size.width * 3.355 * .18 }}>
                            <TitleText minSize={40} maxSize={65} white shadow nolineheight>
                                But we don't take care of it.
                            </TitleText>
                        </div>
                        <div className='recycled__header-title recycled__header-image-xs' style={{ bottom: size.width * 5.752 * .16 }}>
                            <TitleText minSize={40} maxSize={65} white shadow nolineheight>
                                But we don't take care of it.
                            </TitleText>
                        </div>
                    </div>
                }
            </SizeMe>
        );
    }
}
