// Import dependencies
import React from 'react'

// Import styles
import './loader.css'

// Import images
import LoaderIcon from '../../assets/icons/loader.svg'


const Loader = () => (
    <div className='loader__container'>
        <LoaderIcon alt='spiroplastic-loader' className='loader__loader' />
    </div>
)

export default Loader
