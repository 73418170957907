// Import dependencies
import React from 'react'
import { Link } from 'gatsby'

// Import components
import { ParagraphText } from '../styled/UILibrary'

// Import styles
import './bgbutton.css'

const BGButton = ({ title, destination, margin, }) => (
    <Link
        to={destination}
        className='bgbutton__container'
        style={margin ? margin : null}
    >
        <ParagraphText minSize={14} maxSize={16} green>
            {title}
        </ParagraphText>
    </Link>
)

export default BGButton
